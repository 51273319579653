//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';
import XLSX from 'xlsx'

am4core.useTheme(am4themes_animated);
export default {
  name: 'topics',
  data() {
    return {
      showChart: true,
      showInnerLoadingTree: false,
      dragging_Pie_Slices_Data: [],
      isCall: false,
      table_data: [],
      loading: false,
      columns: [
        {
          name: "topic",
          required: true,
          label: "Topic name",
          align: "left",
          field: row => row.name,
          sortable: true
        },
          {
            name: "occurance",
            required: true,
            label: "occurrence #",
            align: "center",
            field: row => row.value,
            sortable: true
          }
      ],
      showTable: false,
      chart: []
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: "getDarkTheme"
    }),
  },
  watch:{
    darkTheme(){
      this.generateDraggingPieSlicesChart()
    }
  },
  methods: {
    ...mapActions({
      requestConversationsTopicParameters: 'requestConversationsTopicParameters',
      generateXMLFile: 'generateXMLFile',
    }),
    generateDraggingPieSlicesChart() {
      const that = this;
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      const data = that.dragging_Pie_Slices_Data.reverse();
      // cointainer to hold both charts
      const container = am4core.create(
        that.$refs.draggingPieSlicesChart,
        am4core.Container,
      );

      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.layout = 'horizontal';

      container.events.on('maxsizechanged', () => {
        chart1.zIndex = 0;
        separatorLine.zIndex = 1;
        // dragText.zIndex = 2;
        chart2.zIndex = 3;
      });

      var chart1 = container.createChild(am4charts.PieChart);
      chart1.fontSize = 11;
      chart1.hiddenState.properties.opacity = 0; // this makes initial fade in effect
      chart1.data = data;
      chart1.radius = am4core.percent(70);
      chart1.innerRadius = am4core.percent(40);
      chart1.zIndex = 1;

      // Enable export
      chart1.exporting.menu = new am4core.ExportMenu();
      chart1.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Topic drilldown-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      const series1 = chart1.series.push(new am4charts.PieSeries());
      series1.dataFields.value = 'value';
      series1.dataFields.category = 'name';
      series1.colors.step = 2;

      // this makes labels to be hidden if they don't 
      // series1.alignLabels = true;
      // series1.labels.template.bent = true;
      // series1.labels.template.disable = true;
      series1.ticks.template.disabled = false;
      series1.alignLabels = false;
      // series1.labels.template.text = "{value.percent.formatNumber('#.0')}%";
      // series1.labels.template.radius = am4core.percent(-40);
      series1.labels.template.relativeRotation = 90;


      // series1.labels.template.adapter.add('radius', (radius, target) => {
      //   if (target.dataItem && target.dataItem.values.value.percent < 15) {
      //     return 25;
      //   }
      //   return radius;
      // });

      // series1.labels.template.adapter.add('fill', (color, target) => {
      //   if (target.dataItem && target.dataItem.values.value.percent < 1) {
      //     return 0;
      //   } else {
      //     series1.alignLabels = true;
      //     // series1.labels.template.bent = true;
      //     series1.labels.template.text = '{name}';
      //     // series1.labels.template.radius =  am4core.percent(15);
      //     // series1.labels.template.padding(0, 0, 0, 0);
      //     // series1.labels.template.fill = am4core.color('#fff');
      //     // series1.ticks.template.disabled = true;

      //     // this makes labels to be hidden if they don't fit
      //     // series1.labels.template.truncate = true;
      //     // series1.labels.template.hideOversized = true;
      //   }
      //   return color;
      // });

      const sliceTemplate1 = series1.slices.template;
      sliceTemplate1.cornerRadius = 5;
      sliceTemplate1.draggable = true;
      sliceTemplate1.inert = true;
      sliceTemplate1.propertyFields.fill = 'color';
      sliceTemplate1.propertyFields.fillOpacity = 'opacity';
      sliceTemplate1.propertyFields.stroke = 'color';
      sliceTemplate1.propertyFields.strokeDasharray = 'strokeDasharray';
      sliceTemplate1.strokeWidth = 1;
      sliceTemplate1.strokeOpacity = 1;

      let zIndex = 5;

      sliceTemplate1.events.on('down', (event) => {
        event.target.toFront();
        // also put chart to front
        const series = event.target.dataItem.component;
        series.chart.zIndex = zIndex++;
      });

      series1.ticks.template.disabled = true;

      sliceTemplate1.states.getKey('active').properties.shiftRadius = 0;

      sliceTemplate1.events.on('dragstop', (event) => {
        handleDragStop(event);
      });

      // separator line and text
      var separatorLine = container.createChild(am4core.Line);
      separatorLine.x1 = 0;
      separatorLine.y2 = 300;
      separatorLine.strokeWidth = 3;
      separatorLine.stroke = am4core.color('#dadada');
      separatorLine.valign = 'middle';
      separatorLine.strokeDasharray = '5,5';

      // var dragText = container.createChild(am4core.Label);
      // // dragText.text = 'Drag slices over the line';
      // dragText.rotation = 90;
      // dragText.valign = 'middle';
      // dragText.align = 'center';

      // dragText.paddingBottom = 5;

      // second chart
      var chart2 = container.createChild(am4charts.PieChart);
      chart2.hiddenState.properties.opacity = 0; // this makes initial fade in effect
      chart2.fontSize = 11;
      chart2.radius = am4core.percent(70);
      chart2.data = data;
      chart2.innerRadius = am4core.percent(40);
      chart2.zIndex = 1;

      // Enable export
      chart2.exporting.menu = new am4core.ExportMenu();
      chart2.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Topic drilldown-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      const series2 = chart2.series.push(new am4charts.PieSeries());
      series2.dataFields.value = 'value';
      series2.dataFields.category = 'name';
      series2.colors.step = 2;

      series2.alignLabels = false;
      series2.labels.template.bent = false;
      series2.labels.template.radius = 30;
      series2.labels.template.padding(0, 0, 0, 0);

      // series2.labels.template.adapter.add('radius', (radius, target) => {
      //   if (target.dataItem && target.dataItem.values.value.percent < 15) {
      //     return 0;
      //   }
      //   return radius;
      // });

      // series2.labels.template.adapter.add('fill', (color, target) => {
      //   if (target.dataItem && target.dataItem.values.value.percent < 15) {
      //     return 0;
      //   } else {
      //     series2.alignLabels = false;
      //     series2.labels.template.bent = true;
      //     series2.labels.template.text = '{name}';
      //     series2.labels.template.radius = am4core.percent(-20);
      //     series2.labels.template.padding(0, 0, 0, 0);
      //     series2.labels.template.fill = am4core.color('#fff');
      //     series2.labels.template.truncate = true;
      //     series2.ticks.template.disabled = true;

      //     // this makes labels to be hidden if they don't fit
      //     series2.labels.template.truncate = true;
      //     series2.labels.template.hideOversized = true;
      //   }
      //   return color;
      // });

      const sliceTemplate2 = series2.slices.template;
      sliceTemplate2.copyFrom(sliceTemplate1);

      series2.ticks.template.disabled = true;

      function handleDragStop(event) {
        const targetSlice = event.target;
        let dataItem1;
        let dataItem2;
        let slice1;
        let slice2;

        if (series1.slices.indexOf(targetSlice) != -1) {
          slice1 = targetSlice;
          slice2 = series2.dataItems.getIndex(targetSlice.dataItem.index).slice;
        } else if (series2.slices.indexOf(targetSlice) != -1) {
          slice1 = series1.dataItems.getIndex(targetSlice.dataItem.index).slice;
          slice2 = targetSlice;
        }

        dataItem1 = slice1.dataItem;
        dataItem2 = slice2.dataItem;

        const series1Center = am4core.utils.spritePointToSvg(
          { x: 0, y: 0 },
          series1.slicesContainer,
        );
        const series2Center = am4core.utils.spritePointToSvg(
          { x: 0, y: 0 },
          series2.slicesContainer,
        );

        const series1CenterConverted = am4core.utils.svgPointToSprite(
          series1Center,
          series2.slicesContainer,
        );
        const series2CenterConverted = am4core.utils.svgPointToSprite(
          series2Center,
          series1.slicesContainer,
        );

        // tooltipY and tooltipY are in the middle of the slice, so we use them to avoid extra calculations
        const targetSlicePoint = am4core.utils.spritePointToSvg(
          { x: targetSlice.tooltipX, y: targetSlice.tooltipY },
          targetSlice,
        );

        if (targetSlice == slice1) {
          if (targetSlicePoint.x > container.pixelWidth / 2) {
            var { value } = dataItem1;

            dataItem1.hide();

            var animation = slice1.animate(
              [
                { property: 'x', to: series2CenterConverted.x },
                { property: 'y', to: series2CenterConverted.y },
              ],
              400,
            );
            animation.events.on('animationprogress', (event) => {
              slice1.hideTooltip();
            });

            slice2.x = 0;
            slice2.y = 0;

            dataItem2.show();
          } else {
            slice1.animate(
              [
                { property: 'x', to: 0 },
                { property: 'y', to: 0 },
              ],
              400,
            );
          }
        }
        if (targetSlice == slice2) {
          if (targetSlicePoint.x < container.pixelWidth / 2) {
            var { value } = dataItem2;

            dataItem2.hide();

            var animation = slice2.animate(
              [
                { property: 'x', to: series1CenterConverted.x },
                { property: 'y', to: series1CenterConverted.y },
              ],
              400,
            );
            animation.events.on('animationprogress', (event) => {
              slice2.hideTooltip();
            });

            slice1.x = 0;
            slice1.y = 0;
            dataItem1.show();
          } else {
            slice2.animate(
              [
                { property: 'x', to: 0 },
                { property: 'y', to: 0 },
              ],
              400,
            );
          }
        }

        toggleDummySlice(series1);
        toggleDummySlice(series2);

        series1.hideTooltip();
        series2.hideTooltip();
      }

      function toggleDummySlice(series) {
        let show = true;
        for (let i = 1; i < series.dataItems.length; i++) {
          const dataItem = series.dataItems.getIndex(i);
          if (dataItem.slice.visible && !dataItem.slice.isHiding) {
            show = false;
          }
        }

        const dummySlice = series.dataItems.getIndex(0);
        if (show) {
          dummySlice.show();
        } else {
          dummySlice.hide();
        }
      }

      series2.events.on('datavalidated', () => {
        const dummyDataItem = series2.dataItems.getIndex(0);
        dummyDataItem.show(0);
        dummyDataItem.slice.draggable = false;
        dummyDataItem.slice.tooltipText = undefined;

        for (let i = 1; i < series2.dataItems.length; i++) {
          series2.dataItems.getIndex(i).hide(0);
        }
      });

      series1.events.on('datavalidated', () => {
        const dummyDataItem = series1.dataItems.getIndex(0);
        dummyDataItem.hide(0);
        dummyDataItem.slice.draggable = false;
        dummyDataItem.slice.tooltipText = undefined;
      });
      that.chart = [chart1 , chart2];
    },
    exportXls(){
      let fileName = `EVO_Dynamics_report${this.currentAIAccount.name}_${this.getFilterDate.start}_to_${this.getFilterDate.end}_message_report_topics_overview`
      var data = _.map(this.table_data , (record , index) => {
          return {
            'S. No': index + 1,
            'Topic Name': record.name,
            'Occurance #': record.value
          }
      });
      /* this line is only needed if you are not adding a script tag reference */
      /* make the worksheet */
      var ws = XLSX.utils.json_to_sheet(data);
      // /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      // /* generate an XLSX file */
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: 'onDateUpdate',
        };
        that.isCall = false;
        that.updateTopicsData(data);
      }
    },
    updateTopicsData(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.showInnerLoadingPackedCircle = true;
        that.showInnerLoadingTree = true;
        that.showPagination = false;
        that.table_data = []
        that.loading = true
        that.isCall = true;
        if (that.chart.length > 0) {
          that.chart[0].dispose();
          that.chart[1].dispose();
        }
        that.requestConversationsTopicParameters(data).then((res) => {
            const data = res.topicData;
            if(data.length > 0){
              that.showTable = true
              that.table_data = data
            }
            const updated_data_array = [
              {
                name: 'Total',
                value: _.reduce(
                  _.pluck(data, 'value'),
                  (memo, num) => memo + num,
                  0,
                ),
              },
              ...data,
            ];
            that.dragging_Pie_Slices_Data = updated_data_array.reverse();
            that.showInnerLoadingTree = false;
            setTimeout(() => {
              that.generateDraggingPieSlicesChart();
            }, 200);
          })
          .catch((err) => {
            that.showChart = false;
            that.showInnerLoading = false;
          })
          .finally(() => {
            that.isCall = false;
            that.loading = false
          });
      }
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updateTopicsData(data);
    },
    isValid(value) {
      if (value === undefined || value === null || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.trim().length === 0)) {
        return false;
      }
      return true;
    },
    exportSectionToXLS(reportType) {
      const that = this;
      that.showInnerLoading = true;
      const data = {
        type: reportType,
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.generateXMLFile(data);
      setTimeout(() => {
        that.showInnerLoading = false;
      }, 1000);
    },
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    that.updateTopicsData(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart.length > 0) {
      this.chart[0].dispose();
      this.chart[1].dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
